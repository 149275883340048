import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Loading", {
        attrs: { active: _vm.salvandoApresentante, "is-full-page": true }
      }),
      _c(
        "div",
        { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
        [
          _c(
            VCard,
            { staticClass: "mb-3", attrs: { align: "left", justify: "left" } },
            [
              _c(
                VCardText,
                { staticClass: "pa-3 font-weight-light white black--text" },
                [
                  _c(
                    VIcon,
                    { staticClass: "primary--text lighten-2 pa-2" },
                    [_vm._v(" mdi-application-edit-outline ")]
                  ),
                  _vm._v("Apresentantes > Gerenciar Apresentantes ")
                ],
                1
              )
            ],
            1
          ),
          _vm.isPartner !== 1
            ? _c(
                VCard,
                {
                  staticClass: "pa-3 mb-4",
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.aplicarFiltros.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    VRow,
                    { staticClass: "pa-3 d-flex align-center" },
                    [
                      _c(
                        VCol,
                        {
                          staticClass: "d-flex flex-row pa-0",
                          attrs: {
                            cols: "4",
                            xs: "6",
                            sm: "6",
                            md: "4",
                            lg: "4"
                          }
                        },
                        [
                          _c(VTextField, {
                            staticClass: "pa-0 ma-0",
                            attrs: {
                              name: "filtro",
                              outlined: "",
                              label: "Filtro",
                              type: "text",
                              "hide-details": "",
                              dense: "",
                              disabled: _vm.loading,
                              "data-cy": "filtroApresentante"
                            },
                            model: {
                              value: _vm.filtroApresentante,
                              callback: function($$v) {
                                _vm.filtroApresentante = $$v
                              },
                              expression: "filtroApresentante"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        {
                          staticClass: "d-flex flex-row",
                          attrs: {
                            cols: "3",
                            xs: "6",
                            sm: "6",
                            md: "2",
                            lg: "2"
                          }
                        },
                        [
                          _c(VSwitch, {
                            attrs: { label: "Mostrar Ativos Comando" },
                            on: { change: _vm.listarApresentantes },
                            model: {
                              value: _vm.mostrarAtivosComando,
                              callback: function($$v) {
                                _vm.mostrarAtivosComando = $$v
                              },
                              expression: "mostrarAtivosComando"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        {
                          staticClass: "d-flex flex-row",
                          attrs: {
                            cols: "2",
                            xs: "6",
                            sm: "6",
                            md: "2",
                            lg: "2"
                          }
                        },
                        [
                          _c(VSwitch, {
                            attrs: { label: "Mostrar Ativos Remessa" },
                            on: { change: _vm.listarApresentantes },
                            model: {
                              value: _vm.mostrarAtivosRemessa,
                              callback: function($$v) {
                                _vm.mostrarAtivosRemessa = $$v
                              },
                              expression: "mostrarAtivosRemessa"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { staticClass: "d-flex justify-end align-center pa-0" },
                        [
                          _vm.subDomain === "experience"
                            ? _c(
                                VBtn,
                                {
                                  attrs: {
                                    color: "blue white--text",
                                    "data-cy": "criarApresentanteExperience",
                                    disabled: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.dialogCriarApresentanteExperience = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(" Criar Apresentante Experience "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("add")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            VBtn,
                            {
                              staticClass: "ml-2",
                              attrs: {
                                color: "green white--text",
                                "data-cy": "criarApresentante",
                                disabled: _vm.loading
                              },
                              on: { click: _vm.criarApresentante }
                            },
                            [
                              _vm._v(" Criar novo Apresentante "),
                              _c(VIcon, { staticClass: "ml-2" }, [
                                _vm._v("add")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.snackbar
            ? _c("Snackbar", {
                attrs: {
                  mostrarSnackbar: _vm.snackbar,
                  corSnackbar: _vm.snackbarColor,
                  mensagemSnackbar: _vm.mensagem
                },
                on: {
                  fecharSnackbar: function($event) {
                    _vm.snackbar = false
                  }
                }
              })
            : _vm._e(),
          _c(VDataTable, {
            staticClass: "elevation-1 pa-3",
            attrs: {
              headers: _vm.headers,
              items: _vm.apresentantes,
              loading: _vm.loading,
              "footer-props": {
                itemsPerPageOptions: [10, 20, 50, -1],
                itemsPerPageText: "Apresentantes por página:"
              },
              "item-key": "usuarios",
              search: _vm.filtroApresentante
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.cra_nacional",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(VSwitch, {
                        attrs: { readonly: "", inset: "" },
                        model: {
                          value: item.cra_nacional,
                          callback: function($$v) {
                            _vm.$set(item, "cra_nacional", $$v)
                          },
                          expression: "item.cra_nacional"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.st_ativo",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(VSwitch, {
                        style: {
                          color: item.st_ativo != "" ? "green" : "red"
                        },
                        attrs: {
                          color: item.st_ativo != "" ? "green" : "red",
                          readonly: "",
                          inset: ""
                        },
                        model: {
                          value: item.st_ativo,
                          callback: function($$v) {
                            _vm.$set(item, "st_ativo", $$v)
                          },
                          expression: "item.st_ativo"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.st_ativo_comando",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.st_ativo_comando === 0
                        ? _c(VIcon, { attrs: { color: "error" } }, [
                            _vm._v("mdi-pause-circle-outline")
                          ])
                        : _c(VIcon, { attrs: { color: "success" } }, [
                            _vm._v("mdi-play-circle-outline")
                          ])
                    ]
                  }
                },
                {
                  key: "item.st_ativo_remessa",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.st_ativo_remessa === 0
                        ? _c(VIcon, { attrs: { color: "error" } }, [
                            _vm._v("mdi-pause-circle-outline")
                          ])
                        : _c(VIcon, { attrs: { color: "success" } }, [
                            _vm._v("mdi-play-circle-outline")
                          ])
                    ]
                  }
                },
                {
                  key: "item.st_mensageria",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        VTooltip,
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "white--text",
                                            style: item.st_mensageria
                                              ? "background-color: " +
                                                _vm.$vuetify.theme.themes.light
                                                  .primary
                                              : "background-color: #b8c4d4",
                                            attrs: {
                                              fab: "",
                                              dark: "",
                                              small: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(VIcon, { attrs: { dark: "" } }, [
                                          _vm._v(" mdi-email-newsletter ")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.st_mensageria
                                    ? "Apresentante apto para Mensageria"
                                    : "Apresentante requer ajustes para habilitar Mensageria"
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.notifica_company_id",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        VTooltip,
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "white--text",
                                            style: item.notifica_company_id
                                              ? "background-color: " +
                                                _vm.$vuetify.theme.themes.light
                                                  .primary
                                              : "background-color: #b8c4d4",
                                            attrs: {
                                              fab: "",
                                              dark: "",
                                              small: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(VIcon, { attrs: { dark: "" } }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.notifica_company_id
                                                  ? "mdi-check-bold"
                                                  : "mdi-close-circle-outline"
                                              ) +
                                              " "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.notifica_company_id
                                    ? "Apresentante habilitado na Notifica"
                                    : "Apresentante não habilitado na Notifica"
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.st_protestado",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        VTooltip,
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "white--text",
                                            style: item.st_protestado
                                              ? "background-color: " +
                                                _vm.$vuetify.theme.themes.light
                                                  .primary
                                              : "background-color: #b8c4d4",
                                            attrs: {
                                              fab: "",
                                              dark: "",
                                              small: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(VIcon, { attrs: { dark: "" } }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.st_protestado
                                                  ? "mdi-check-bold"
                                                  : "mdi-close-circle-outline"
                                              ) +
                                              " "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.st_protestado
                                    ? "Apresentante habilitado na Protestado"
                                    : "Apresentante não habilitado na Protestado"
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.is_admin",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        VIcon,
                        {
                          style: {
                            color: item.is_admin != "" ? "green" : "red"
                          }
                        },
                        [_vm._v(_vm._s(_vm.getBolean(item.is_admin)))]
                      )
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(props) {
                    return [
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-2 grey white--text",
                          attrs: {
                            elevation: "0",
                            width: "90%",
                            loading: _vm.dialogLoading || _vm.loading,
                            "data-cy": "editarApresentante" + props.index
                          },
                          on: {
                            click: function($event) {
                              return _vm.exibirDetalhe(props.item)
                            }
                          }
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "mr-2",
                              style: { color: "white" },
                              attrs: { medium: "" },
                              on: {
                                click: function($event) {
                                  return _vm.exibirDetalhe(props.item)
                                }
                              }
                            },
                            [_vm._v("edit")]
                          ),
                          _vm._v("Editar ")
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm.dialog
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _vm.snackbar
                ? _c("Snackbar", {
                    attrs: {
                      mostrarSnackbar: _vm.snackbar,
                      timeoutSnackbar: 4000,
                      corSnackbar: _vm.snackbarColor,
                      mensagemSnackbar: _vm.mensagem
                    },
                    on: {
                      fecharSnackbar: function($event) {
                        _vm.snackbar = false
                      }
                    }
                  })
                : _vm._e(),
              _c(
                VCard,
                [
                  _c(
                    VCardTitle,
                    { staticClass: "pa-0" },
                    [
                      _c(
                        VToolbar,
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeDialog }
                            },
                            [_c(VIcon, [_vm._v("mdi-close")])],
                            1
                          ),
                          _c(VToolbarTitle, [_vm._v("Criar Apresentante")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardText,
                    { staticClass: "px-8 py-4" },
                    [
                      _c("CriarApresentante", {
                        attrs: {
                          apresentante: _vm.apresentante,
                          listaTiposApresentante: _vm.listaTiposApresentante
                        },
                        on: {
                          setIsInvalid: function($event) {
                            _vm.isInvalid = $event
                          },
                          criarApresentante: _vm.verificaApresentante,
                          fecharModal: _vm.closeDialog
                        }
                      })
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardActions,
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "end",
                        padding: "8px 48px 8px 16px"
                      }
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "ml-5",
                          attrs: {
                            color: "green white--text",
                            "data-cy": "salvarApresentante",
                            disabled: _vm.isInvalid,
                            width: "120"
                          },
                          on: { click: _vm.verificaApresentante }
                        },
                        [_vm._v(" Salvar ")]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "ml-5",
                          attrs: { dense: "", color: "error", width: "120" },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v(" Cancelar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dialogEditar
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogEditar,
                callback: function($$v) {
                  _vm.dialogEditar = $$v
                },
                expression: "dialogEditar"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VCardTitle,
                    { staticClass: "pa-0" },
                    [
                      _c(
                        VToolbar,
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeDialogEditar }
                            },
                            [_c(VIcon, [_vm._v("mdi-close")])],
                            1
                          ),
                          _c(VToolbarTitle, [_vm._v("Editar Apresentante")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardText,
                    { staticClass: "px-8 py-4" },
                    [
                      _c("EditarApresentante", {
                        attrs: {
                          apresentante: _vm.apresentante,
                          "apresentante-entidade": _vm.apresentanteEntidade,
                          "ativo-protestado": _vm.ativoProtestado,
                          loading: _vm.loading,
                          listaTiposApresentante: _vm.listaTiposApresentante
                        },
                        on: {
                          setIsInvalid: function($event) {
                            _vm.isInvalid = $event
                          },
                          salvarApresentante: _vm.alterarApresentante,
                          fecharModal: _vm.closeDialogEditar,
                          loadingFalse: _vm.loadingFalse,
                          atualizarApresentante: function($event) {
                            _vm.apresentante = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardActions,
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "end",
                        padding: "8px 48px 8px 16px"
                      }
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "ml-5",
                          attrs: {
                            color: "green white--text",
                            "data-cy": "alterarApresentante",
                            disabled: _vm.isInvalid,
                            width: "120"
                          },
                          on: { click: _vm.alterarApresentante }
                        },
                        [_vm._v(" Salvar ")]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "ml-5",
                          attrs: { dense: "", color: "error", width: "120" },
                          on: { click: _vm.closeDialogEditar }
                        },
                        [_vm._v(" Cancelar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        VDialog,
        {
          attrs: {
            "max-width": "800px",
            persistent: "",
            scrollable: "",
            color: "background",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogCriarApresentanteExperience,
            callback: function($$v) {
              _vm.dialogCriarApresentanteExperience = $$v
            },
            expression: "dialogCriarApresentanteExperience"
          }
        },
        [
          _c(
            VCard,
            [
              _c(
                VCardTitle,
                { staticClass: "pa-0" },
                [
                  _c(
                    VToolbar,
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function($event) {
                              ;(_vm.dialogCriarApresentanteExperience = false),
                                (_vm.feedbackExperience = "")
                            }
                          }
                        },
                        [_c(VIcon, [_vm._v("mdi-close")])],
                        1
                      ),
                      _c(VToolbarTitle, [
                        _vm._v("Criar Apresentante Experience")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(VDivider),
              _vm.loadingCriarApresentanteExperience
                ? _c(
                    VCardText,
                    { staticClass: "px-8 py-4" },
                    [
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { staticClass: "d-flex justify-center" },
                            [
                              _c(VProgressCircular, {
                                attrs: { color: "primary", indeterminate: "" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { staticClass: "d-flex justify-center" },
                            [_vm._v(" Enviando... ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : !_vm.loadingCriarApresentanteExperience &&
                  _vm.feedbackExperience
                ? _c(
                    VCardText,
                    { staticClass: "px-8 py-4" },
                    [
                      _c(
                        VRow,
                        [
                          _c(VCol, [
                            _c("span", [_vm._v(_vm._s(_vm.feedbackExperience))])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    VCardText,
                    { staticClass: "px-8 py-4" },
                    [
                      _c(
                        VRow,
                        [
                          _c(VCol, [
                            _c("span", [
                              _vm._v(
                                "Você gostaria de criar um apresentante Experience BVS ou Recuperi?"
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "float-right",
                                  attrs: {
                                    color: "primary",
                                    "data-cy": "criarApresentanteExperienceBVS"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.CriarApresentanteExperience(
                                        "bvs"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" BVS ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    color: "primary",
                                    "data-cy":
                                      "criarApresentanteExperienceRecuperi"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.CriarApresentanteExperience(
                                        "recuperi"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Recuperi ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }