<template>
    <div>
        <v-card>
            <v-container>
                <form ref="usuarioForm">
                    <v-col sm="6" md="6" lg="5" class="ma-auto text-center">
                        <v-icon size="150">mdi-playlist-plus</v-icon>
                        <v-card-text
                            class="
                                        primary--text
                                        font-weight-black
                                        display-1
                                    "
                        >
                            Editar Apresentante
                        </v-card-text>
                        <v-card-text class="">
                            Siga os passos ao lado para Editar um apresentante
                        </v-card-text>
                    </v-col>
                    <v-row>
                        <v-col sm="6" md="6" lg="6">
                            <v-form ref="form" v-model="valid">
                                <v-row class="my-6">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Número do CNPJ
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="8" md="7">
                                        <v-text-field
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="cnpj"
                                            label="Exemplo: 00.000.000/0000-00"
                                            :rules="[
                                                rules.required,
                                                rules.cpfOrCnpj
                                            ]"
                                            v-model="apresentante.nu_cnpj"
                                            v-maska="'##.###.###/####-##'"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Código do Apresentante
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="8" md="7">
                                        <v-text-field
                                            filled
                                            disabled
                                            prepend-icon="mdi-information-outline"
                                            name="cd_apresentante"
                                            label="Campo não editável"
                                            max="3"
                                            v-model="
                                                apresentante.cd_apresentante
                                            "
                                            :rules="nameRulesConfirmation"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Nome de Exibição
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="6" md="7">
                                        <v-text-field
                                            solo
                                            label="Nome de exibição"
                                            prepend-icon="mdi-information-outline"
                                            name="nome_exibicao"
                                            type="text"
                                            v-model="apresentante.nome_exibicao"
                                            :rules="nameRulesConfirmation"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Razão Social
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="7" md="7">
                                        <v-text-field
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="nm_razao_social"
                                            label="Razão Social"
                                            v-model="
                                                apresentante.nm_razao_social
                                            "
                                            :rules="nameRulesConfirmation"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Link de contato
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="7" md="7">
                                        <v-text-field
                                            label="Link de contato"
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="linkContato"
                                            v-model="apresentante.link_contato"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >E-mail Apresentante
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="7" md="7">
                                        <v-text-field
                                            label="E-mail de contato"
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="emailApresentante"
                                            v-model="
                                                apresentante.email_apresentante
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        sm="6"
                                        md="6"
                                    >
                                        <v-card-text class="font-weight-light">
                                            <v-switch
                                                outlined
                                                color="success"
                                                v-model="
                                                    apresentante.st_ativo_comando
                                                "
                                                label="Ativo Comando"
                                                required
                                                name="ativoComando"
                                                data-cy="ativoComando"
                                                hide-details="auto"
                                                class="mt-0"
                                            ></v-switch>
                                        </v-card-text>
                                    </v-col>                                    
                                    <v-col
                                        sm="6"
                                        md="6"
                                    >
                                        <v-card-text class="font-weight-light">
                                            <v-switch
                                                outlined
                                                color="success"
                                                v-model="
                                                    apresentante.st_ativo_remessa
                                                "
                                                label="Ativo Remessa"
                                                required
                                                name="ativoRemessa"
                                                data-cy="ativoRemessa"
                                                hide-details="auto"
                                                class="mt-0"
                                            ></v-switch>
                                        </v-card-text>
                                    </v-col>
                                    <v-col
                                        sm="6"
                                        md="6"
                                        class="d-flex pt-0"
                                    >
                                        <v-card-text class="font-weight-light d-flex">
                                            <v-tooltip top>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs
                                                    }"
                                                >
                                                        <v-switch
                                                            outlined
                                                            color="success"
                                                            v-model="
                                                                apresentante.st_ativo
                                                            "
                                                            label="Está ativo?"
                                                            required
                                                            data-cy="btnStatusApresentante"
                                                            hide-details="auto"
                                                            class="mt-0"
                                                        ></v-switch>
                                                        <v-icon
                                                            class="ml-3"
                                                            color="grey lighten-2"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            help
                                                        </v-icon>
                                                </template>
                                                <span
                                                    >Selecione essa opção para
                                                    definir se o usuário está ativo.
                                                    Caso o usuário estiver inativo,
                                                    não irá conseguir acessar o
                                                    sistema.
                                                </span>
                                            </v-tooltip>
                                        </v-card-text>
                                    </v-col>
                                    <v-col
                                        sm="6"
                                        md="6"
                                        class="d-flex pt-0"
                                    >
                                        <v-card-text class="font-weight-light d-flex">
                                            <v-tooltip top>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs
                                                    }"
                                                >
                                                    
                                                    <v-switch
                                                        outlined
                                                        color="success"
                                                        v-model="
                                                            apresentante.cra_nacional
                                                        "
                                                        label="CRA Nacional"
                                                        required
                                                        name="cra_nacional"
                                                        data-cy="craNacional"
                                                        hide-details="auto"
                                                        class="mt-0"
                                                    ></v-switch>
                                                    <v-icon
                                                        class="ml-3"
                                                        color="grey lighten-2"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        help
                                                    </v-icon>
                                                    
                                                </template>
                                                <span
                                                    >Selecione essa opção para
                                                    definir se o apresentante é
                                                    pertencente ao CRA Nacional.
                                                </span>
                                            </v-tooltip>
                                        </v-card-text>
                                    </v-col>                                    
                                </v-row>
                            </v-form>
                        </v-col>
                        <v-col sm="6" md="6" lg="6">
                            <v-form ref="form" v-model="valid">
                                <v-row class="my-6">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Partner ID
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="7" md="7">
                                        <v-select
                                            :items="partners"
                                            clearable
                                            label="Selecione o Parter ID"
                                            item-text="fullname"
                                            item-value="id"
                                            name="partner"
                                            prepend-icon="mdi-information-outline"
                                            filled
                                            type="number"
                                            v-model="apresentante.partner_id"
                                        ></v-select>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Tipo do Apresentante
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="7" md="7">
                                        <v-select
                                            :items="listaTiposApresentante"
                                            solo
                                            item-text="cd_tipo_apresentante"
                                            item-value="id_tipo_apresentante"
                                            name="tipo"
                                            prepend-icon="mdi-information-outline"
                                            label="Tipo Apresentante"
                                            required
                                            v-model="
                                                apresentante.id_tipo_apresentante
                                            "
                                            data-cy="apresentanteTipo"
                                        ></v-select>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >URL endpoint
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="8" md="7">
                                        <v-text-field
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="url_endpoint"
                                            label="Exemplo: https://endereco.com.br/exemplo"
                                            v-model="
                                                apresentante.callback_url_endpoint
                                            "
                                            data-cy="urlEndpoint"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Callback Certificado
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="8" md="7">
                                        <v-text-field
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="certificado_apresentante"
                                            label="Informe o certificado do Apresentante"
                                            v-model="
                                                apresentante.callback_certificate
                                            "
                                            data-cy="certificado"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Chave Callback
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="8" md="7">
                                        <v-text-field
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="key_apresentante"
                                            label="Informe a chave do apresentante"
                                            v-model="apresentante.callback_key"
                                            data-cy="certificado"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon>mdi-chevron-right</v-icon
                                            >Parametros callback JWT
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="8" md="7">
                                        <v-textarea
                                            solo
                                            prepend-icon="mdi-information-outline"
                                            name="callback_jwt_request_params"
                                            label="Informe o usuário de autenticação callback"
                                            v-model="
                                                apresentante.callback_jwt_request_params
                                            "
                                            data-cy="callback_jwt_request_params"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                        <v-col
                            v-if="!ativoProtestado"
                            sm="6"
                            md="6"
                            class="mx-auto"
                            style="display: flex"
                        >
                            <v-card heigth="100%" class="mx-3" color="green">
                                <v-card-title class="white--text">
                                    <v-btn
                                        small
                                        fab
                                        class="ma-3"
                                        color="green lighten-2 white--text"
                                    >
                                        <v-icon class="white--text"
                                            >mdi-information</v-icon
                                        > </v-btn
                                    >Apresentante não habilitado na Protestado.
                                </v-card-title>
                                <v-card-text class="white--text">
                                    Clique no botão abaixo para habilitar
                                    apresentante na plataforma Protestado
                                </v-card-text>
                                <v-card-text class="white--text">
                                    Ao confirmar, verifique os dados de
                                    integração que serão gerados.
                                </v-card-text>
                                <v-btn
                                    :loading="loading"
                                    @click="ativaApresentanteProtestado"
                                    v-if="!ativoProtestado"
                                    class="d-flex justify-center ma-3"
                                >
                                    Habilitar Apresentante
                                </v-btn>
                            </v-card>
                        </v-col>
                        <v-col
                            v-if="ativoProtestado"
                            sm="6"
                            md="6"
                            class="mx-auto"
                            style="display: flex"
                        >
                            <v-card width="100%" color="green white--text">
                                <v-card-title>
                                    <v-btn small fab class="ma-3">
                                        <v-icon small>mdi-check</v-icon>
                                    </v-btn>
                                    Esse é um apresentante ativo na Protestado
                                </v-card-title>

                                <v-row class="mx-3 px-3">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon color="white"
                                                >mdi-chevron-right</v-icon
                                            >Protestado UUID
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="6" md="8">
                                        <v-text-field
                                            label="Id do Apresentante na Protestado(UUID)"
                                            solo
                                            readonly
                                            prepend-icon="mdi-information-outline white--text"
                                            name="protestadoUuid"
                                            hint="Informações não editáveis"
                                            v-model="apresentanteEntidade.id"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="mx-3 px-3">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon color="white"
                                                >mdi-chevron-right</v-icon
                                            >Nome do Apresentante
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="6" md="8">
                                        <v-text-field
                                            label="Nome do Apresentante na Protestado"
                                            solo
                                            readonly
                                            prepend-icon="mdi-information-outline white--text"
                                            name="protestadoName"
                                            hint="Informações não editáveis"
                                            v-model="apresentanteEntidade.name"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="mx-3 px-3">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon color="white"
                                                >mdi-chevron-right</v-icon
                                            >Data de Criação
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="6" md="8">
                                        <v-text-field
                                            label="Data de Criação"
                                            solo
                                            readonly
                                            prepend-icon="mdi-information-outline white--text"
                                            name="created_at_protestado"
                                            hint="Informações não editáveis"
                                            v-model="computedDataCriacao"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="mx-3 px-3">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon color="white"
                                                >mdi-chevron-right</v-icon
                                            >Documento CPF/CNPJ
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="6" md="8">
                                        <v-text-field
                                            label="Documento"
                                            solo
                                            readonly
                                            v-maska="[
                                                '###.###.###-##',
                                                '##.###.###/####-##'
                                            ]"
                                            prepend-icon="mdi-information-outline white--text"
                                            name="documento_protestado"
                                            hint="Informações não editáveis"
                                            v-model="
                                                apresentanteEntidade.documento_cpf_cnpj
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="mx-3 px-3">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon color="white"
                                                >mdi-chevron-right</v-icon
                                            >E-mail
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="6" md="8">
                                        <v-text-field
                                            label="E-mail"
                                            solo
                                            readonly
                                            prepend-icon="mdi-information-outline white--text"
                                            name="email_protestado"
                                            hint="Informações não editáveis"
                                            v-model="apresentanteEntidade.email"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="mx-3 px-3">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon color="white"
                                                >mdi-chevron-right</v-icon
                                            >Status
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="12" md="8">
                                        <v-card color="white">
                                            <v-switch
                                                outlined
                                                color="success"
                                                v-model="
                                                    apresentanteEntidade.is_active
                                                "
                                                label="Status"
                                                required
                                                prepend-icon="mdi-information-outline white--text"
                                                readonly
                                                disabled
                                                name="status"
                                                data-cy="status"
                                            ></v-switch>
                                        </v-card>
                                    </v-col>
                                    <v-col sm="6" md="12">
                                        <v-card-text>
                                            Informações atualizadas através da
                                            plataforma Protestado.
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col
                            v-if="!ativoNotifica"
                            v-show="!ativoNotifica"
                            sm="6"
                            md="6"
                            class="mx-auto"
                            style="display: flex"
                        >
                            <v-card heigth="100%" class="mx-3" color="indigo">
                                <v-card-title class="white--text">
                                    <v-btn
                                        small
                                        fab
                                        class="ma-3"
                                        color="yellow lighten-2 indigo--text"
                                    >
                                        <v-icon class="indigo--text"
                                            >mdi-information</v-icon
                                        > </v-btn
                                    >Apresentante não habilitado na Notifica.
                                </v-card-title>
                                <v-card-text class="white--text">
                                    Clique no botão abaixo para habilitar
                                    apresentante na plataforma notifica
                                </v-card-text>
                                <v-card-text class="white--text">
                                    Ao confirmar, verifique os dados de
                                    integração que serão gerados.
                                </v-card-text>
                                <v-btn
                                    :loading="loading"
                                    @click="habilitarApresentanteNotifica"
                                    v-if="!ativoNotifica"
                                    class="d-flex justify-center ma-3"
                                >
                                    Habilitar Apresentante
                                </v-btn>
                            </v-card>
                        </v-col>
                        <v-col
                            v-if="ativoNotifica"
                            sm="6"
                            md="6"
                            class="mx-auto"
                            style="display: flex"
                        >
                            <v-card
                                width="100%"
                                color="indigo white--text"
                                v-if="loadingData"
                            >
                                <v-row class="mx-3 px-3">
                                    <v-col sm="12" md="12">
                                        <v-card-text class="font-weight-light">
                                            <v-icon color="white"
                                                >mdi-chart-donut</v-icon
                                            >
                                            Buscando dados do apresentante ...
                                        </v-card-text>
                                        <v-progress-linear
                                            indeterminate
                                            color="white"
                                        ></v-progress-linear>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card
                                width="100%"
                                color="indigo white--text"
                                v-if="!loadingData"
                            >
                                <v-card-title>
                                    <v-btn small fab class="ma-3">
                                        <v-icon small>mdi-check</v-icon>
                                    </v-btn>
                                    Esse é um apresentante ativo na Notifica
                                </v-card-title>

                                <v-row class="mx-3 px-3">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon color="white"
                                                >mdi-chevron-right</v-icon
                                            >
                                            Nome da Company
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="6" md="8">
                                        <v-text-field
                                            label="Nome da Company"
                                            solo
                                            readonly
                                            prepend-icon="mdi-information-outline white--text"
                                            name="notifica_company_name"
                                            hint="Informações não editáveis"
                                            v-model="apresentanteNotifica.name"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="mx-3 px-3">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon color="white"
                                                >mdi-chevron-right</v-icon
                                            >
                                            ID da Company
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="6" md="8">
                                        <v-text-field
                                            label="Id do Apresentante na Notifica(UUID)"
                                            solo
                                            readonly
                                            prepend-icon="mdi-information-outline white--text"
                                            name="notificaUuid"
                                            hint="Informações não editáveis"
                                            v-model="apresentanteNotifica.id"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="mx-3 px-3">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon color="white"
                                                >mdi-chevron-right</v-icon
                                            >
                                            Tipo Documento
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="6" md="8">
                                        <v-text-field
                                            label="CPF/CNPJ"
                                            solo
                                            readonly
                                            prepend-icon="mdi-information-outline white--text"
                                            name="notifica_document_type"
                                            hint="Informações não editáveis"
                                            v-model="
                                                apresentanteNotifica.document_type
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="mx-3 px-3">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon color="white"
                                                >mdi-chevron-right</v-icon
                                            >
                                            Documento
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="6" md="8">
                                        <v-text-field
                                            label="Documento habilitado"
                                            solo
                                            readonly
                                            prepend-icon="mdi-information-outline white--text"
                                            name="notifica_document_number"
                                            hint="Informações não editáveis"
                                            v-model="
                                                apresentanteNotifica.document_number
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="mx-3 px-3">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon color="white"
                                                >mdi-chevron-right</v-icon
                                            >
                                            Email
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="6" md="8">
                                        <v-text-field
                                            label="E-mail"
                                            solo
                                            readonly
                                            prepend-icon="mdi-information-outline white--text"
                                            name="email"
                                            hint="Informações não editáveis"
                                            v-model="apresentanteNotifica.email"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="mx-3 px-3">
                                    <v-col sm="6" md="4">
                                        <v-card-text class="font-weight-light">
                                            <v-icon color="white"
                                                >mdi-chevron-right</v-icon
                                            >
                                            Ativo
                                        </v-card-text>
                                    </v-col>
                                    <v-col sm="6" md="8">
                                        <v-text-field
                                            label="Status"
                                            solo
                                            readonly
                                            prepend-icon="mdi-information-outline white--text"
                                            name="is_active"
                                            hint="Informações não editáveis"
                                            v-model="
                                                apresentanteNotifica.is_active
                                            "
                                        />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </form>
                <Snackbar
                    v-if="snackbar"
                    :mostrarSnackbar="snackbar"
                    :corSnackbar="colorSnackbar"
                    :mensagemSnackbar="mensagemSnackbar"
                    @fecharSnackbar="snackbar = false"
                />                
            </v-container>
        </v-card>
    </div>
</template>

<script>
import ApresentanteService from '@/services/apresentanteService';
import Vue from 'vue';
import { OnRules } from 'vuetify-js-utils';
import moment from 'moment';

export default {
    mixins: [OnRules],
    name: 'DetalheApresentante',
    props: {
        apresentante: {
            type: Object,
            required: false
        },
        apresentanteEntidade: {
            type: Object,
            required: false
        },
        ativoProtestado: {
            type: Boolean,
            required: false,
            default: null
        },
        listaTiposApresentante: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            apresentanteService: new ApresentanteService(Vue.http, this.$store),
            computedDateCreated: null,
            loading: false,
            partners: [],
            show1: false,
            errorMessages: null,
            apresentantes: {
                id_user: '',
                ds_login: '',
                st_ativo: false,
                is_admin: false,
                name: '',
                password: '',
                email: ''
            },
            id_user: '',
            ds_login: '',
            st_ativo: false,
            enabled: false,
            password: '',
            email: '',
            valid: null,
            createPassword: '',
            emailConfirmation: '',
            passwordRules: [
                v => !!v || 'Senha é requerida',
                v =>
                    (v && v.length > 2) ||
                    'A senha deve ter 2 ou mais caracteres'
            ],
            passwordRulesConfirmation: [
                v => !!v || 'Senha é requerida',
                v =>
                    v == this.apresentante.password ||
                    'As senhas devem ser iguais'
            ],
            emailRulesConfirmation: [
                v => !!v || 'Email é requerido',
                v => v.length >= 2 || 'O e-mail deve ter 2 ou mais caracteres'
            ],
            nameRulesConfirmation: [
                v => !!v || 'Campo Obrigatório',
                v => (v && v.length >= 0) || 'Esse campo deve ser obrigatório'
            ],
            ativoNotifica: false,
            notificaCompanyId: null,
            snackbar: false,
            colorSnackbar: 'grey',
            mensagemSnackbar: null,
            apresentanteNotifica: [],
            loadingData: false
        };
    },
    methods: {
        async controlarPausaApresentante() {
            try {
                this.loading = true;
                const stPausaPayload = !this.apresentante.st_pausa;
                const textoResponse = await this.apresentanteService.apresentantePausa(
                    this.apresentante.cd_apresentante,
                    stPausaPayload
                );
                this.exibirSnackbar('success', textoResponse);
                const apresentante = await this.buscarApresentanteAtualizado();
                this.$emit('atualizarApresentante', apresentante);
            } catch (e) {
                this.exibirSnackbar(
                    'error',
                    'Erro ao atualizar status de pausa do Apresentante.'
                );
            } finally {
                this.loading = false;
            }
        },
        async buscarApresentanteAtualizado() {
            try {
                const response = await this.apresentanteService.detalhesApresentante(
                    this.apresentante
                );
                return response.body;
            } catch (e) {
                this.exibirSnackbar('error', 'Erro ao buscar Apresentante.');
            }
        },
        exibirSnackbar(colorSnackbar, mensagemSnackbar) {
            this.snackbar = true;
            this.colorSnackbar = colorSnackbar;
            this.mensagemSnackbar = mensagemSnackbar;
        },
        verificaCredenciaisNotifica() {
            this.loading = true;
            let apresentante = this.apresentante.cd_apresentante;
            let apresentanteService = new ApresentanteService(
                Vue.http,
                this.$store
            );
            apresentanteService
                .verificaCredenciaisApresentanteNotifica(apresentante)
                .then(
                    response => {
                        if (
                            response &&
                            response.body.response.notifica_company_id
                        ) {
                            this.exibirSnackbar(
                                'success',
                                'Verificação realizada com sucesso'
                            );
                            this.loadingData = true;
                            this.loading = false;
                            this.ativoNotifica = true;
                            this.notificaCompanyId =
                                response.body.response.notifica_company_id;
                            console.log(
                                response.body.response.notifica_company_id
                            );
                            this.verifidaDetalhesApresentanteNotifica(
                                apresentante
                            );
                        } else {
                            this.loading = false;
                            this.ativoNotifica = false;
                            this.exibirSnackbar(
                                'grey',
                                'Apresentante não retornou credenciais de habilitação na Notifica'
                            );
                        }
                    },
                    error => {
                        this.loading = false;
                        this.mensagem = error;
                        console.log(error);
                    }
                );
        },
        verifidaDetalhesApresentanteNotifica(notificaCompanyId) {
            this.loadingData = true;
            this.loading = true;
            let apresentanteService = new ApresentanteService(
                Vue.http,
                this.$store
            );
            apresentanteService
                .verificaDetalhesApresentanteNotifica(notificaCompanyId)
                .then(
                    response => {
                        if (response.status == 200) {
                            this.exibirSnackbar(
                                'success',
                                'Verificação realizada com sucesso'
                            );
                            this.loading = false;
                            this.loadingData = false;
                            this.apresentanteNotifica = response.body;
                        } else {
                            this.loading = false;
                            this.loadingData = false;
                            this.exibirSnackbar(
                                'error',
                                'Falha na verificação do apresentante na Notifica'
                            );
                            console.log(
                                'Apresentante não habilitado na Notifica, não foram encontradas credenciais'
                            );
                        }
                    },
                    error => {
                        this.mensagem = error;
                        console.log(error);
                    }
                );
        },
        habilitarApresentanteNotifica() {
            this.loading = true;
            let apresentante = this.apresentante.cd_apresentante;
            let apresentanteService = new ApresentanteService(
                Vue.http,
                this.$store
            );
            apresentanteService
                .habilitarApresentanteNotifica(apresentante)
                .then(
                    response => {
                        if (response.status == 200) {
                            this.exibirSnackbar(
                                'success',
                                'Apresentante habilitado com sucesso'
                            );
                            this.loading = false;
                            this.verificaCredenciaisNotifica();
                        } else {
                            this.loading = false;
                            this.exibirSnackbar(
                                'error',
                                'Falha na habilitação do apresentante'
                            );
                        }
                    },
                    error => {
                        this.loading = false;
                        this.exibirSnackbar(
                            'error',
                            'Falha na habilitação do apresentante, verifique os dados do apresentante'
                        );
                        this.mensagem = error;
                        console.log(error);
                    }
                );
        },
        formatDate(item) {
            let dataFormatada;
            if (item) {
                dataFormatada = moment(item).format('DD/MM/YYYY');
            } else {
                dataFormatada = '--';
            }
            return dataFormatada;
        },
        ativaApresentanteProtestado() {
            this.loading = true;
            let apresentante = this.apresentante.cd_apresentante;
            let apresentanteService = new ApresentanteService(
                Vue.http,
                this.$store
            );
            apresentanteService
                .habilitarApresentanteProtestado(apresentante)
                .then(
                    response => {
                        console.log(response);
                        this.loading = false;
                        this.$emit('fecharModal');
                    },
                    error => {
                        this.mensagem = error;
                        console.log(error);
                    }
                );
        },
        validarSalvar() {
            if (this.valid) {
                this.$emit('salvarApresentante');
            } else {
                this.$refs.form.validate();
            }
        },
        listaPartners() {
            {
                return new Promise((resolve, reject) => {
                    try {
                        let apresentanteService = new ApresentanteService(
                            Vue.http,
                            this.$store
                        );
                        apresentanteService.listaPartners().then(
                            response => {
                                if (response.status == 200) {
                                    this.partners = response.body;
                                }
                            },
                            error => {
                                console.log(error);
                            }
                        );
                    } catch (e) {
                        this.mensagem = e;
                        console.log(e);
                        reject();
                    }
                });
            }
        },
        keyupListener(e) {
            if (!e) e = window.event;
            const keyCode = e.code || e.key;

            if (keyCode === 'Escape') {
                this.$emit('fecharModal');
            } else if (keyCode === 'Enter' || keyCode === 'NumpadEnter') {
                this.validarSalvar();
            }
        }
    },
    watch: {
        valid(val) {
            this.$emit('setIsInvalid', !val);
        }
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.keyupListener);
    },
    mounted() {
        document.addEventListener('keyup', this.keyupListener);
        this.listaPartners();
        this.verificaCredenciaisNotifica();
    },
    computed: {
        computedDataCriacao: {
            get: function() {
                return this.formatDate(this.apresentanteEntidade.created_at);
            },
            set: function(value) {
                this.computedDateCreated = value;
            }
        }
    }
};
</script>

<style scoped></style>
