import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VCard,
        [
          _c(VContainer, [
            _c(
              "form",
              { ref: "usuarioForm" },
              [
                _c(
                  VRow,
                  [
                    _c(
                      VCol,
                      {
                        staticClass: "ma-auto text-center",
                        attrs: { sm: "6", md: "6", lg: "5" }
                      },
                      [
                        _c(VIcon, { attrs: { size: "150" } }, [
                          _vm._v("mdi-playlist-plus")
                        ]),
                        _c(
                          VCardText,
                          {
                            staticClass:
                              "primary--text font-weight-black display-1"
                          },
                          [_vm._v(" Novo Apresentante ")]
                        ),
                        _c(VCardText, {}, [
                          _vm._v(
                            " Siga os passos ao lado para criar um novo apresentante "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      VCol,
                      { attrs: { sm: "6", md: "6", lg: "7" } },
                      [
                        _c(
                          VForm,
                          {
                            ref: "form",
                            model: {
                              value: _vm.valid,
                              callback: function($$v) {
                                _vm.valid = $$v
                              },
                              expression: "valid"
                            }
                          },
                          [
                            _c(
                              VRow,
                              { staticClass: "my-6" },
                              [
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v("Número do CNPJ ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "8", md: "7" } },
                                  [
                                    _c(VTextField, {
                                      directives: [
                                        {
                                          name: "maska",
                                          rawName: "v-maska",
                                          value: "##.###.###/####-##",
                                          expression: "'##.###.###/####-##'"
                                        }
                                      ],
                                      attrs: {
                                        solo: "",
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        name: "cnpj",
                                        label: "Exemplo: 00.000.000/0000-00",
                                        rules: [
                                          _vm.rules.required,
                                          _vm.rules.cpfOrCnpj
                                        ],
                                        required: "",
                                        "data-cy": "apresentanteCnpj"
                                      },
                                      model: {
                                        value: _vm.apresentante.nu_cnpj,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "nu_cnpj",
                                            $$v
                                          )
                                        },
                                        expression: "apresentante.nu_cnpj"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v("Código do Apresentante ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "8", md: "7" } },
                                  [
                                    _c(VTextField, {
                                      attrs: {
                                        solo: "",
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        name: "cd_apresentante",
                                        label: "Ex.:AYS",
                                        maxlength: "3",
                                        rules: _vm.codigoRulesConfirmation,
                                        required: "",
                                        onkeypress: _vm.permitirLetrasNumeros(
                                          true
                                        ),
                                        "data-cy": "apresentanteCodigo"
                                      },
                                      model: {
                                        value: _vm.apresentante.cd_apresentante,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "cd_apresentante",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "\n                                            apresentante.cd_apresentante\n                                        "
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v("Nome de Exibição ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "7" } },
                                  [
                                    _c(VTextField, {
                                      attrs: {
                                        solo: "",
                                        label: "Nome de exibição",
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        name: "nome_exibicao",
                                        type: "text",
                                        rules: _vm.nameRulesConfirmation,
                                        "data-cy": "apresentanteNome"
                                      },
                                      model: {
                                        value: _vm.apresentante.nome_exibicao,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "nome_exibicao",
                                            $$v
                                          )
                                        },
                                        expression: "apresentante.nome_exibicao"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v("Razão Social ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "7", md: "7" } },
                                  [
                                    _c(VTextField, {
                                      attrs: {
                                        solo: "",
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        name: "nm_razao_social",
                                        label: "Razão Social",
                                        rules: _vm.nameRulesConfirmation,
                                        required: "",
                                        "data-cy": "apresentanteRazao"
                                      },
                                      model: {
                                        value: _vm.apresentante.nm_razao_social,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "nm_razao_social",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                                            apresentante.nm_razao_social\n                                        "
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v("Usuário CRA ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "7", md: "7" } },
                                  [
                                    _c(VTextField, {
                                      attrs: {
                                        solo: "",
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        name: "usuarioCra",
                                        label: "Usuário CRA",
                                        rules: _vm.nameRulesConfirmation,
                                        required: "",
                                        "data-cy": "apresentanteCRA"
                                      },
                                      model: {
                                        value: _vm.apresentante.cra_user,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "cra_user",
                                            $$v
                                          )
                                        },
                                        expression: "apresentante.cra_user"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v("Senha CRA ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "7", md: "7" } },
                                  [
                                    _c(VTextField, {
                                      attrs: {
                                        solo: "",
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        name: "craPassword",
                                        label: "Senha Cra",
                                        rules: _vm.nameRulesConfirmation,
                                        required: "",
                                        "data-cy": "apresentanteSenhaCRA"
                                      },
                                      model: {
                                        value: _vm.apresentante.cra_passwd,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "cra_passwd",
                                            $$v
                                          )
                                        },
                                        expression: "apresentante.cra_passwd"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v("Link de contato ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "7", md: "7" } },
                                  [
                                    _c(VTextField, {
                                      attrs: {
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        name: "linkContato",
                                        solo: "",
                                        label: "Link de contato"
                                      },
                                      model: {
                                        value: _vm.apresentante.link_contato,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "link_contato",
                                            $$v
                                          )
                                        },
                                        expression: "apresentante.link_contato"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v("E-mail Apresentante ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "7", md: "7" } },
                                  [
                                    _c(VTextField, {
                                      attrs: {
                                        solo: "",
                                        label: "E-mail de contato",
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        name: "emailApresentante",
                                        "data-cy": "apresentanteEmail"
                                      },
                                      model: {
                                        value:
                                          _vm.apresentante.email_apresentante,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "email_apresentante",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                                            apresentante.email_apresentante\n                                        "
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v(
                                          "Ambiente do Apresentante (Partner ID) "
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "7", md: "7" } },
                                  [
                                    _c(VSelect, {
                                      attrs: {
                                        items: _vm.partners,
                                        solo: "",
                                        "item-text": "fullname",
                                        "item-value": "id",
                                        name: "partner",
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        label: "Partner ID",
                                        required: "",
                                        "data-cy": "apresentanteAmbiente"
                                      },
                                      model: {
                                        value: _vm.apresentante.partner_id,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "partner_id",
                                            $$v
                                          )
                                        },
                                        expression: "apresentante.partner_id"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v("Tipo do Apresentante ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "7", md: "7" } },
                                  [
                                    _c(VSelect, {
                                      attrs: {
                                        items: _vm.listaTiposApresentante,
                                        solo: "",
                                        "item-text": "cd_tipo_apresentante",
                                        "item-value": "id_tipo_apresentante",
                                        name: "tipo",
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        label: "Tipo Apresentante",
                                        required: "",
                                        "data-cy": "apresentanteTipo"
                                      },
                                      model: {
                                        value:
                                          _vm.apresentante.id_tipo_apresentante,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "id_tipo_apresentante",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                                            apresentante.id_tipo_apresentante\n                                        "
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  {
                                    staticClass: "mx-auto",
                                    staticStyle: { display: "flex" },
                                    attrs: { sm: "6", md: "11" }
                                  },
                                  [
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VRow,
                                                  [
                                                    _c(VSwitch, {
                                                      attrs: {
                                                        outlined: "",
                                                        color: "success",
                                                        label: "Está ativo?",
                                                        required: "",
                                                        name: "is_active",
                                                        "data-cy":
                                                          "apresentanteStatus"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.apresentante
                                                            .st_ativo,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.apresentante,
                                                            "st_ativo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "\n                                                        apresentante.st_ativo\n                                                    "
                                                      }
                                                    }),
                                                    _c(
                                                      VIcon,
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "ml-3",
                                                            attrs: {
                                                              color:
                                                                "grey lighten-2"
                                                            }
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [_vm._v(" help ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "Selecione essa opção para definir se o usuário está ativo. Caso o usuário estiver inativo, não irá conseguir acessar o sistema. "
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VRow,
                                                  {
                                                    staticStyle: {
                                                      "justify-content":
                                                        "center"
                                                    }
                                                  },
                                                  [
                                                    _c(VSwitch, {
                                                      attrs: {
                                                        outlined: "",
                                                        color: "success",
                                                        label:
                                                          "Apresentante CRA Nacional",
                                                        required: "",
                                                        name: "cra_nacional",
                                                        "data-cy": "craNacional"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.apresentante
                                                            .cra_nacional,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.apresentante,
                                                            "cra_nacional",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "\n                                                        apresentante.cra_nacional\n                                                    "
                                                      }
                                                    }),
                                                    _c(
                                                      VIcon,
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "ml-3",
                                                            attrs: {
                                                              color:
                                                                "grey lighten-2"
                                                            }
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [_vm._v(" help ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "Selecione essa opção para definir se o apresentante é pertencente ao CRA Nacional. "
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v("URL endpoint ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "8", md: "7" } },
                                  [
                                    _c(VTextField, {
                                      attrs: {
                                        solo: "",
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        name: "url_endpoint",
                                        label:
                                          "Exemplo: https://endereco.com.br/exemplo",
                                        "data-cy": "urlEndpoint"
                                      },
                                      model: {
                                        value:
                                          _vm.apresentante
                                            .callback_url_endpoint,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "callback_url_endpoint",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                                            apresentante.callback_url_endpoint\n                                        "
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v("Callback Certificado ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "8", md: "7" } },
                                  [
                                    _c(VTextField, {
                                      attrs: {
                                        solo: "",
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        name: "certificado_apresentante",
                                        label:
                                          "Informe o certificado do Apresentante",
                                        "data-cy": "certificado_apresentante"
                                      },
                                      model: {
                                        value:
                                          _vm.apresentante.callback_certificate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "callback_certificate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                                            apresentante.callback_certificate\n                                        "
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v("Chave Callback ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "8", md: "7" } },
                                  [
                                    _c(VTextField, {
                                      attrs: {
                                        solo: "",
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        name: "key_apresentante",
                                        label:
                                          "Informe a chave do apresentante",
                                        "data-cy": "key_apresentante"
                                      },
                                      model: {
                                        value: _vm.apresentante.callback_key,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "callback_key",
                                            $$v
                                          )
                                        },
                                        expression: "apresentante.callback_key"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "6", md: "4" } },
                                  [
                                    _c(
                                      VCardText,
                                      { staticClass: "font-weight-light" },
                                      [
                                        _c(VIcon, [
                                          _vm._v("mdi-chevron-right")
                                        ]),
                                        _vm._v("Parametros callback JWT ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { sm: "8", md: "7" } },
                                  [
                                    _c(VTextarea, {
                                      attrs: {
                                        solo: "",
                                        "prepend-icon":
                                          "mdi-information-outline",
                                        name: "callback_jwt_request_params",
                                        label:
                                          "Informe o usuário de autenticação callback",
                                        "data-cy": "callback_jwt_request_params"
                                      },
                                      model: {
                                        value:
                                          _vm.apresentante
                                            .callback_jwt_request_params,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.apresentante,
                                            "callback_jwt_request_params",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                                            apresentante.callback_jwt_request_params\n                                        "
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }